.adminContent
{
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.adminContentChild
{
    width: calc(100% - 256px);
}
.css-krdrhq-MuiTypography-root {
    margin: 0px;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600 !important;
    font-size: 24px  !important;
    line-height: 1.167;
    letter-spacing: -0.06px;
    color: rgb(23, 43, 77);
}

.css-137rogq-MuiTypography-root {
    margin: 0px 0px 0.35em;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 1.6;
    letter-spacing: -0.05px;
    color: rgb(107, 119, 140);
}

header.MuiPaper-root,
header .MuiToolbar-root 
{
    height: 64px;
}

.textCenter
{
    text-align: center;
    margin-top: 10%;
}



@media(max-width:1250px)
{

    .adminContentChild
    {
        width: 100%;
    }
}